.loading-logo {
  width: 149px;
}

// @keyframes spin {
//   from {
//     transform: rotate(360deg);
//   }
//   to {
//     transform: rotate(0deg);
//   }
// }
.ant-image-img {
  padding: 8px;
}
