.ant-collapse-extra {
  position: absolute;
}
.headers-card {
  .ant-card-body {
    padding: 12px 20px 12px 20px;
    .ant-row {
      gap: 6px;
    }
  }
}
