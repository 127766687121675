.ant-card-body {
  .ant-spin-container {
    display: inline-flex;
    flex-wrap: wrap;
    .ant-card {
      margin: 9px;
    }
    .ant-card-head {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      .ant-card-head-title > h4 {
        display: contents;
        font-weight: 600;
      }
    }
    .ant-card-body {
      padding: 18px;
    }
    .ant-card-bordered {
      border: 2px solid #f0f0f0;
    }
  }
}
