.App {
  position: relative;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: chartreuse;
}

.white-space-nowrap {
  white-space: nowrap;
}

.clickable:hover {
  cursor: pointer;
}

.ant-modal-header > .ant-modal-title {
  color: #32c5d2;
  font-size: 16px;
  font-weight: bold;
}
