.revenue-tab-title {
  display: flex;
  align-items: center;
  gap: 9px;
}
.stats-row {
  padding: 0px 20px 20px 20px;
  .ant-card-body {
    padding: 12px 24px 12px 24px;
    font-size: large;
    color: rgb(147, 28, 143);
  }
  .ant-statistic-title {
    font-size: 16px;
  }
  .ant-statistic-content {
    font-size: 24px;
  }
}
